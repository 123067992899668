import Repository from '@/repositories/Repository'

export default {

    addInstrument(instrumentName) {
        var response = Repository.post('/instrument/addinstrument', {
            instrumentname: instrumentName,       
        });
        return response;
    },

    listInstruments(page, itemsPerPage, search) {
        var response = Repository.get('/instrument/listinstruments', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search            
            } 
        });
        return response;
    },

};
