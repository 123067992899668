<template>
  <v-app
    class="backdrop"
  >
    <v-app-bar
      v-if="showAppBar"
      app
      clipped-left
    >
      <div class="d-flex align-center">

        <v-app-bar-nav-icon
          v-if="showDrawer"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <router-link to="/">
          <v-img
            alt="teacherPRO"
            src="@/assets/logo.png"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn 
        v-if="$store.state.auth.authenticated && (userUtils.getRole() != 1)"
        class="button ma-2"
        rounded
        @click="contactUsDialog=true"
      >
        <v-icon>mdi-message</v-icon>
        <span 
        >Contact Us</span>
      </v-btn>

      <v-menu 
        v-if="$store.state.auth.authenticated"
        offset-y
      >

        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            class="button"
            rounded v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
            <span 
              v-if="$store.state.auth.user.profile"
            >{{ $store.state.auth.user.profile.firstname + ' ' + $store.state.auth.user.profile.lastname }}</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="signOut" style="min-width:150px">
            Sign Out
          </v-list-item>
        </v-list>

      </v-menu>

    </v-app-bar>

    <v-navigation-drawer 
      v-if="showDrawer"
      v-model="drawer"
      app
      clipped
    >
      <v-list nav>

        <v-list-item 
          v-if="$router.resolve({path: '/application'}).route.meta.allowedRoles.includes(userUtils.getRole()) && (userUtils.getTeacherStatusId() == 1)"
          link
          to="/application"
        >
          <v-list-item-icon>
            <v-icon>mdi-file-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Application</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>

        <v-list-item 
          v-if="$router.resolve({path: '/dashboard/admin'}).route.meta.allowedRoles.includes(userUtils.getRole()) && activeTeacher()"
          link
          to="/dashboard/admin"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>

        <!--<v-list-item 
          v-if="$router.resolve({path: '/dashboard/teacher'}).route.meta.allowedRoles.includes(userUtils.getRole()) && activeTeacher()"
          link
          to="/dashboard/teacher"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>-->
 
        <v-list-item 
          v-if="$router.resolve({path: '/lessonrequests'}).route.meta.allowedRoles.includes(userUtils.getRole()) && activeTeacher()"
          link
          to="/teacherprofile"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>My Profile</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>

        <v-list-item 
          v-if="$router.resolve({path: '/lessonrequests'}).route.meta.allowedRoles.includes(userUtils.getRole()) && activeTeacher()"
          link
          to="/teacheradvertisement"
        >
          <v-list-item-icon>
            <v-icon>mdi-image-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>My Advertisement</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>

        <v-list-item 
          v-if="$router.resolve({path: '/inbox'}).route.meta.allowedRoles.includes(userUtils.getRole()) && activeTeacher()"
          link
          to="/inbox"
        >
          <v-list-item-icon>
            <v-icon>mdi-inbox</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inbox</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>

        <v-list-item 
          v-if="$router.resolve({path: '/lessonrequests'}).route.meta.allowedRoles.includes(userUtils.getRole()) && activeTeacher()"
          link
          to="/lessonrequests"
        >
          <v-list-item-icon>
            <v-icon>mdi-comment-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Lesson Requests</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>

        <v-list-item 
          v-if="$router.resolve({path: '/applicationmanager'}).route.meta.allowedRoles.includes(userUtils.getRole())"
          link
          to="/applicationmanager"
        >
          <v-list-item-icon>
            <v-icon>mdi-text-box-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Applications</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>

        <v-list-item 
          v-if="$router.resolve({path: '/manage'}).route.meta.allowedRoles.includes(userUtils.getRole())"
          link
          to="/manage"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage</v-list-item-title>
          </v-list-item-content>        
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <v-dialog
      v-model="contactUsDialog"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title>
          Contact teacherPRO
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-textarea
            v-model="contactMessage"
            label="Message"
            color="#b0d5c7"
          ></v-textarea>        
        </v-card-text>

        <v-card-actions>

          <v-container class="d-flex">
            <v-spacer></v-spacer>
            <v-btn  
              class="ma-2"
              @click="contactUsDialog = false"
            >
              Cancel
            </v-btn>          
            <v-btn  
              class="ma-2 button"
              rounded
              style="width: 100px" 
              @click="sendMessage()"
            >
              Send
            </v-btn>
          </v-container>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showError"
      color="#ff914d"
    >
      {{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showError = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="showSuccess"
      color="#00bf63"
    >
      {{ success }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showSuccess = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </v-app>

</template>

<script>
import userUtils from '@/utils/UserUtils'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const MessageRepository = RepositoryFactory.get('message');

export default {
  name: 'App',

  data: () => ({
    userUtils: userUtils,
    drawer: true,
    error: '',
    showError: false,    
    success: '',
    showSuccess: false,    
    contactUsDialog: false,
    contactMessage: ''
  }),

  computed: {
    showAppBar() {
      let show = false;
      show = show || (this.$route.path == '/signup/teacher');
      show = show || (this.$route.path == '/verifyemail');
      show = show || (this.$route.path.substring(0, 13) == '/verifyteacher/');
      show = show || userUtils.signedIn();
      return show;
    },
    showDrawer() {
      let show = false;
      show = show || userUtils.signedIn();
      show = show && (this.$route.path != '/signup/teacher');
      show = show && (this.$route.path != '/verifyemail');
      show = show && (this.$route.path.substring(0, 13) != '/verifyteacher/');
      return show;
    } 
  },

  methods: {

    activeTeacher() {
      let result = true;
      let user = this.$store.state.auth.user;
      if (user.role.roleid == 2) {
        result = userUtils.getTeacherStatusId() === 2;
      }
      return result;
    },

    async sendMessage() {

      let data = null;

      data = await MessageRepository.startChat(0);
      data = data.data;
      if (data.success) {

        let chatId = data.response.chatid;
        data = await MessageRepository.sendMessage(chatId, this.contactMessage);
        data = data.data;
        if (data.success) {
          this.success = 'Message successfully submitted to teacherPRO support.';
          this.showSuccess = true;
          this.contactMessage = '';
          this.$router.push("/inbox");
        } else {
          this.error = data.error.message;
          this.showError = true;          
        }

      } else {          
        this.error = data.error.message;
        this.showError = true;
      }
      this.contactUsDialog = false;
    },

    signOut() {
      this.$store.commit('signOut'); 
      this.$router.push("/");
    },

  },

};
</script>

<style scoped>

  .v-application {
   font-family: Arial !important;
   background-color: #f6f9f6 !important;
 }

  .backdrop {
    background-color: #eeeeee;
  }

  .button {
    text-transform: unset !important;
    color: #54595f  !important;
    background-color: #b0d5c7 !important;
    &:hover {
        background-color: #fbd1a2 !important;
    }
  }

</style>