import Repository from '@/repositories/Repository'

export default {

    addSubject(subjectName) {
        var response = Repository.post('/subject/addsubject', {
            subjectname: subjectName,       
        });
        return response;
    },

    listSubjects(page, itemsPerPage, search) {
        var response = Repository.get('/subject/listsubjects', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search            
            } 
        });
        return response;
    },

};
