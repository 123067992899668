import Repository from '@/repositories/Repository'

export default {

    getTeacherStats() {
        var response = Repository.get('/stats/getteacherstats', {
            params:{          
            } 
        });
        return response;
    },

};
