import store from '@/store'

export default { 

    getRole() {
        let role = 0;
        if (store.state.auth.authenticated) {
            role = store.state.auth.user.role.roleid;
        }
        return role;    
    },

    getAccessToken() {
        return store.state.auth.accessToken;
    },

    getTeacherStatusId() {
        let statusId = 0;
        if (store.state.auth.authenticated) {
            let user = store.state.auth.user;
            if (user.role.roleid == 2) {
                if (user.profile !== null) {
                    statusId = user.profile.status.statusid;
                }
            }
        }
        return statusId;
    },

    signedIn() {
        return store.state.auth.authenticated;  
    }

};
