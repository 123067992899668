import Repository from '@/repositories/Repository'

export default {

    getAdvertisement() {
        var response = Repository.get('/teacher/getadvertisement', {
            params:{         
            } 
        });
        return response;
    },

    updateAdvertisement(advertisement) {
        var response = Repository.post('/teacher/updateadvertisement', {
            advertisement: advertisement
        });
        return response;       
    },

    updatePersonalDetails(displayName, phoneNumber) {
        var response = Repository.post('/teacher/updatepersonaldetails', {
            displayname: displayName,
            phonenumber: phoneNumber
        });
        return response;       
    },

};
