import Repository from '@/repositories/Repository'

export default {

    listLevels(page, itemsPerPage, search) {
        var response = Repository.get('/level/listlevels', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search            
            } 
        });
        return response;
    },

};
