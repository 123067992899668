import Repository from '@/repositories/Repository'

export default {

    addSport(sportName) {
        var response = Repository.post('/sport/addsport', {
            sportname: sportName,       
        });
        return response;
    },

    listSports(page, itemsPerPage, search) {
        var response = Repository.get('/sport/listsports', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search            
            } 
        });
        return response;
    },

};
