import Repository from '@/repositories/Repository'

export default {

    addNoncurricular(noncurricularName) {
        var response = Repository.post('/noncurricular/addnoncurricular', {
            noncurricularname: noncurricularName,       
        });
        return response;
    },

    listNoncurricular(page, itemsPerPage, search) {
        var response = Repository.get('/noncurricular/listnoncurricular', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search            
            } 
        });
        return response;
    },

};
