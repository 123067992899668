import ApplicationRepository from './controllers/ApplicationRepository'
import InstrumentRepository from './controllers/InstrumentRepository'
import LanguageRepository from './controllers/LanguageRepository'
import LevelRepository from './controllers/LevelRepository'
import LocationRepository from './controllers/LocationRepository'
import MessageRepository from './controllers/MessageRepository'
import NoncurricularRepository from './controllers/NoncurricularRepository'
import QualificationRepository from './controllers/QualificationRepository'
import SportRepository from './controllers/SportRepository'
import StatsRepository from './controllers/StatsRepository'
import SubjectRepository from './controllers/SubjectRepository'
import TeacherRepository from './controllers/TeacherRepository'
import UserRepository from './controllers/UserRepository'

const repositories = {
    application: ApplicationRepository,
    instrument: InstrumentRepository,
    language: LanguageRepository,
    level: LevelRepository,
    location: LocationRepository,
    message: MessageRepository,
    noncurricular: NoncurricularRepository,
    qualification: QualificationRepository,
    sport: SportRepository,
    stats: StatsRepository,
    subject: SubjectRepository,
    teacher: TeacherRepository,
    user: UserRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
