import Repository from '@/repositories/Repository'

export default {

    startChat(withUserId) {
        var response = Repository.post('/message/startchat', {
            withuserid: withUserId
        });
        return response;
    },

    listChats() {
        var response = Repository.get('/message/listchats', {
            params:{
            } 
        });
        return response;
    },

    listMessages(chatId) {
        var response = Repository.get('/message/listmessages', {
            params:{
                chatid: chatId,
            } 
        });
        return response;
    },

    sendMessage(chatId, message) {
        var response = Repository.post('/message/sendmessage', {
            chatid: chatId,
            message: message
        });
        return response;
    },

};
