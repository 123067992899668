import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { Buffer } from 'buffer';

Vue.use(Vuex)

export default new Vuex.Store({
  
  state: {
    auth: {
      authenticated: false, 
      accessToken: '',
      user: {}
    }
  },

  getters: {
  },

  mutations: {

    signIn(state, accessToken) {            
      // extract payload

      const payloadBase64 = accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(Buffer.from(payloadBase64, 'base64').toString('utf8'));
      /*const payload = decodeURIComponent(window.atob(payloadBase64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));*/

      state.auth.user = payload.user;
      state.auth.accessToken = accessToken;
      state.auth.authenticated = true;
    },    

    signOut (state) {
      state.auth.accessToken = '';      
      state.auth.user = {};
      state.auth.authenticated = false;
    },

    setProfileDetails(state, profile) {       
      state.auth.user.profile.displayname = profile.displayName;
      state.auth.user.profile.phonenumber = profile.phoneNumber;
    },      

  },

  actions: {
  },

  modules: {
  },

  plugins: [createPersistedState({
    key: 'TeacherPro'
  })]
  
})
