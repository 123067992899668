import Repository from '@/repositories/Repository'

export default {

    authenticate(email, password) {
        var response = Repository.post('/user/authenticate', {
            email: email,
            password: password
        });
        return response;
    },

    listUsers(page, itemsPerPage, search) {
        var response = Repository.get('/user/listusers', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search            
            } 
        });
        return response;
    },

    getProfilePicture() {
        var response = Repository.get('/user/getprofilepicture', {
            params:{         
            } 
        });
        return response;
    },

    signUpTeacher(email, password, firstname, lastname) {
        var response = Repository.post('/user/signupteacher', {
            email: email,
            password: password,
            firstname: firstname,
            lastname: lastname
        });
        return response;
    },

    uploadProfilePicture(fileData) {
        var response = Repository.post('/user/uploadprofilepicture', {
            filedata: fileData
        });
        return response;
    },

    verifyEmail(hash) {
        var response = Repository.post('/user/verifyemail', {
            hash: hash,
        });
        return response;
    },

};
