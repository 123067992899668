import Repository from '@/repositories/Repository'

export default {

    approveApplication(applicationId) {
        var response = Repository.post('/application/approveapplication', {
            applicationid: applicationId, 
        });
        return response;
    },

    deleteCertificate(certificateId) {
        var response = Repository.post('/application/deletecertificate', {
            certificateid: certificateId
        });
        return response;
    },

    denyApplication(applicationId, application) {
        var response = Repository.post('/application/denyapplication', {
            applicationid: applicationId,
            application: application,       
        });
        return response;
    },
    
    getApplication() {
        var response = Repository.get('/application/getapplication', {
            params:{    
            } 
        });
        return response;
    },

    getCertificate(certificateId) {
        var response = Repository.get('/application/getcertificate', {
            params:{  
                certificateid: certificateId 
            } 
        });
        return response;
    },

    listSubmissions(page, itemsPerPage, search) {
        var response = Repository.get('/application/listsubmissions', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search            
            } 
        });
        return response;
    },

    resubmitApplication(applicationId, application) {
        var response = Repository.post('/application/resubmitapplication', {
            applicationid: applicationId,
            application: application,       
        });
        return response;
    },

    storeApplication(application) {
        var response = Repository.post('/application/storeapplication', {
            application: application,       
        });
        return response;
    },

    storeApplicationById(applicationId, application) {
        var response = Repository.post('/application/storeapplicationbyid', {
            applicationid: applicationId,
            application: application,       
        });
        return response;
    },

    submitApplication(application) {
        var response = Repository.post('/application/submitapplication', {
            application: application,       
        });
        return response;
    },

    uploadCertificate(fileName, fileData) {
        var response = Repository.post('/application/uploadcertificate', {
            filename: fileName,   
            filedata: fileData
        });
        return response;
    },

};
