import Repository from '@/repositories/Repository'

export default {

    addCity(provinceId, cityName) {
        var response = Repository.post('/location/addcity', {
            provinceid: provinceId,  
            cityname: cityName,       
        });
        return response;
    },

    getProvince(provinceId) {
        var response = Repository.get('/location/getprovince', {
            params:{
                provinceid: provinceId,
            } 
        });
        return response;
    },

    listCities(page, itemsPerPage, search, provinceId) {
        var response = Repository.get('/location/listcities', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search,
                provinceid: provinceId,
            } 
        });
        return response;
    },

    listProvinces(page, itemsPerPage, search) {
        var response = Repository.get('/location/listprovinces', {
            params:{
                page: page,
                itemsperpage: itemsPerPage,
                search: search,
            } 
        });
        return response;
    },

};
